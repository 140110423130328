import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide65/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide65/image/img.png"
import slider1 from "@components/pageGuide/guides/guide65/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide65/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide65/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      8 estrategias para mejorar el primer contacto con leads en educación
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      Mejorar el primer contacto con leads en educación puede definir el éxito de una estrategia de captación. Sabes que un seguimiento rápido y personalizado genera mayor interés en los programas ofrecidos.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    Automatizar respuestas iniciales, segmentar mensajes según intereses y ofrecer información clara permite captar la atención desde el inicio.
    <br /><br />
    Además, establecer un canal de comunicación directo y disponible incrementa la confianza y las posibilidades de conversión.
  </p>
)

const data = {
  start: {
    support: "Guía | Educación",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Fortalecer la presencia digital.",
      },
      {
        key: 2,
        text: "Participación de eventos virtuales.",
      },
      {
        key: 3,
        text: " Uso del social proof.",
      },
      {
        key: 4,
        text: " Lead nurturing para leads.",
      },
      {
        key: 5,
        text: "Cómo centralizar la información.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
